import { Node } from '@tiptap/core'

interface IframeOptions {
  allowFullscreen: boolean
  HTMLAttributes: {
    [key: string]: unknown
  }
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    iframe: {
      /**
       * Add an iframe
       */
      setIframe: (options: { src: string }) => ReturnType
    }
  }
}

export const Iframe = Node.create<IframeOptions>({
  name: 'iframe',
  inline: true,
  group: 'inline',
  selectable: false,
  draggable: true,

  addOptions() {
    return {
      allowFullscreen: true,
      HTMLAttributes: {
        class: 'iframe-wrapper',
      },
    }
  },

  addAttributes() {
    return {
      src: {
        default: null,
      },
      frameborder: {
        default: 0,
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: () => this.options.allowFullscreen,
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iframe',
      },
    ]
  },
  renderHTML({ HTMLAttributes }) {
    return ['iframe', HTMLAttributes]
  },
  addCommands() {
    return {
      setIframe: (options) => (commandProps) => {
        const { selection } = commandProps.tr
        const node = this.type.create(options)

        commandProps.dispatch?.(commandProps.tr.insert(selection.from, node))

        return true
      },
    }
  },
})
