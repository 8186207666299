import { Node } from '@tiptap/core'
import { PluginKey } from '@tiptap/pm/state'
import type { SuggestionOptions } from '@tiptap/suggestion'
import { Suggestion } from '@tiptap/suggestion'

interface User {
  id: number
  name: string
  userId?: number
  slug?: string
}

interface Range {
  from: number
  to: number
}

interface InsertMentionParam {
  user: User
  range?: Range
}
declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mention: {
      insertMention: ({ user, range }: InsertMentionParam) => ReturnType
    }
  }
}

type Render = ReturnType<NonNullable<SuggestionOptions['render']>>
interface CustomExtensionOptions {
  suggestion: {
    items: SuggestionOptions<User>['items']
    onStart?: Render['onStart']
    onChange?: Render['onUpdate']
    onExit?: Render['onExit']
    onKeyDown?: Render['onKeyDown']
    onUpdate?: Render['onUpdate']
    pluginKey?: SuggestionOptions['pluginKey']
    char?: string
    allowSpaces?: boolean
    startOfLine?: boolean
    decorationClass?: string
  }
  mentionClass: string
}

interface User {
  id: number
  name: string
  userId?: number
  slug?: string
}

const MentionPluginKey = new PluginKey('mention')

export const Mention = Node.create<CustomExtensionOptions>({
  name: 'mention',
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,
  addAttributes() {
    return {
      id: {
        default: null,
      },
      userId: {
        default: null,
      },
      slug: {
        default: null,
      },
      name: {
        default: null,
      },
    }
  },
  addOptions: () => {
    return {
      suggestion: {
        items: () => [],
        char: '@',
        pluginKey: MentionPluginKey,
        allowSpaces: false,
        startOfLine: false,
        decorationClass: 'mention-suggestion',
      },
      mentionClass: 'mention',
    }
  },
  renderHTML({ node }) {
    return [
      'a',
      {
        class: this.options.mentionClass,
        'data-mention-id': node.attrs.id,
        'data-user-id': node.attrs.userId,
        'data-user-slug': node.attrs.slug,
        href: `/wapers/${node.attrs.slug}`,
      },
      `${this.options.suggestion.char}${node.attrs.name} `,
    ]
  },
  parseHTML() {
    return [
      {
        tag: 'a[data-mention-id]',
        getAttrs: (node) => {
          if (!(node instanceof HTMLElement)) return {}

          const id = node.getAttribute('data-mention-id')
          const userId = node.getAttribute('data-user-id')
          const slug = node.getAttribute('data-user-slug')
          const name = this.options.suggestion.char
            ? node.innerText.split(this.options.suggestion.char).join('')
            : node.innerText

          return { id, userId, slug, name }
        },
      },
    ]
  },
  addCommands: () => {
    return {
      insertMention:
        ({ user, range }) =>
        ({ commands, editor }) => {
          if (!range) return false
          return commands.insertContentAt(range, {
            type: editor.schema.nodes.mention.name,
            attrs: user,
          })
        },
    }
  },
  addProseMirrorPlugins() {
    return [
      Suggestion<User>({
        editor: this.editor,
        char: this.options.suggestion.char,
        allowSpaces: this.options.suggestion.allowSpaces,
        startOfLine: this.options.suggestion.startOfLine,
        decorationClass: this.options.suggestion.decorationClass,
        render: () => ({
          onStart: this.options.suggestion.onStart,
          onChange: this.options.suggestion.onChange,
          onUpdate: this.options.suggestion.onChange,
          onExit: this.options.suggestion.onExit,
          onKeyDown: this.options.suggestion.onKeyDown,
        }),
        items: this.options.suggestion.items,
      }),
    ]
  },
})
