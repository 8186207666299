<template>
  <div class="c-editor">
    <div
      class="c-editor__embed"
      :class="{ 'is-visible': showEmbedSelection }"
    >
      <div>
        <input
          ref="inputEmbed"
          type="text"
          placeholder="URL de la vidéo Youtube, Dailymotion ou Vimeo"
          @keydown.enter.prevent
        />
        <p
          v-if="embedError"
          class="error"
        >
          <strong>{{ embedError }}</strong>
        </p>
        <ButtonSimple
          label="Ajouter"
          color="white"
          type="button"
          @click="addEmbed"
        />
      </div>
    </div>
    <div class="c-editor__container">
      <EditorContent
        class="c-editor__content"
        :editor="editor"
      />
    </div>
    <div>
      <div class="c-editor__menubar">
        <button
          type="button"
          class="c-editor__action"
          :class="{ 'is-active': editor?.isActive('bold') }"
          @click="editor?.chain().focus().toggleBold().run()"
        >
          <img src="~assets/icons/editor-bold.svg" />
        </button>

        <button
          type="button"
          class="c-editor__action"
          :class="{ 'is-active': editor?.isActive('italic') }"
          @click="editor?.chain().focus().toggleItalic().run()"
        >
          <img src="~assets/icons/editor-italic.svg" />
        </button>

        <button
          type="button"
          class="c-editor__action"
          :class="{ 'is-active': editor?.isActive('underline') }"
          @click="editor?.chain().focus().toggleUnderline().run()"
        >
          <img src="~assets/icons/editor-underline.svg" />
        </button>

        <button
          v-if="list"
          type="button"
          class="c-editor__action"
          :class="{ 'is-active': editor?.isActive('bulletList') }"
          @click="editor?.chain().focus().toggleBulletList().run()"
        >
          <img src="~assets/icons/editor-list.svg" />
        </button>

        <Tippy
          v-if="imagesCount >= props.maxImages"
          trigger="mouseenter focus"
          :hide-on-click="false"
          content="Vous ne pouvez pas ajouter plus de 5 images"
          to=".image-button"
        />
        <button
          type="button"
          class="c-editor__action image-button"
          :class="{ disabled: imagesCount >= props.maxImages }"
          @click="addImage"
        >
          <img src="~assets/icons/editor-image.svg" />
        </button>

        <Tippy
          v-if="isMaxEmbedsReached"
          trigger="mouseenter focus"
          :hide-on-click="false"
          content="Vous ne pouvez ajouter qu'une seule iframe"
          to=".embed-button"
        />

        <button
          type="button"
          class="c-editor__action embed-button"
          :class="{ disabled: isMaxEmbedsReached }"
          @click="toggleEmbed"
        >
          <img src="~assets/icons/editor-embed.svg" />
        </button>

        <Tippy
          v-if="mention"
          ref="mentionTooltipRef"
          :hide-on-click="true"
          interactive
          tag="div"
          trigger="manual"
          :arrow="false"
          animate-fill
          class="c-editor__action"
          animation="shift-away"
        >
          <template #default>
            <button
              type="button"
              class="c-editor__action"
              :class="{ 'is-active': mentionTooltipRef?.state.isVisible }"
              @click="tryMention"
            >
              @
            </button>
          </template>
          <template #content>
            <div class="suggestion-list">
              <template v-if="hasResults">
                <div
                  v-for="(user, index) in filteredUsers"
                  :key="user.id"
                  class="suggestion-list__item"
                  :class="{ 'is-focused': navigatedUserIndex === index }"
                  @click="selectUser(user)"
                >
                  {{ user.name }}
                </div>
              </template>
              <div
                v-else
                class="is-empty"
              >
                Pas de Waper
              </div>
            </div>
          </template>
        </Tippy>
      </div>
    </div>
    <input
      id="imageEditor"
      ref="inputFile"
      type="file"
      name="imageEditor"
      class="c-editor__input"
      accept="image/png, image/jpeg, image/gif"
      @change="onFileChange"
    />
  </div>
</template>

<script setup lang="ts">
import Image from '@tiptap/extension-image'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { EditorContent, useEditor } from '@tiptap/vue-3'
import 'tippy.js/animations/shift-away.css'
import 'tippy.js/dist/backdrop.css'
import 'tippy.js/dist/tippy.css'
import { Tippy } from 'vue-tippy'
import { Iframe } from '~/components/shared/Editor/Iframe'
import { Mention } from '~/components/shared/Editor/Mention'
import { API } from '~/utils/constants'
import { buildEmbedUrl, extractVideoId } from '~/utils/helpers'
import { TrailingNode } from './trailing-node'

interface User {
  id: number
  name: string
  userId?: number
  slug?: string
}

interface Range {
  from: number
  to: number
}

const props = withDefaults(
  defineProps<{
    placeholder?: string
    maxImages?: number
    maxEmbeds?: number
    list?: boolean
    mention?: boolean
    wapersSuggestions?: User[]
  }>(),
  {
    placeholder: 'Que voulez-vous dire ?',
    maxImages: 5,
    maxEmbeds: 1,
    list: true,
    mention: false,
    wapersSuggestions: () => [],
  },
)

const modelValue = defineModel<string>({ default: '' })

const emit = defineEmits<{
  enterEditor: [value: boolean]
}>()

const suggestionRange = ref<Range | undefined>({ from: 0, to: 0 })
const mentionTooltipRef = ref<typeof Tippy | null>(null)
const suggestionQuery = ref<string>()
const filteredUsers = ref<User[]>([])
const navigatedUserIndex = ref(0)
const showEmbedSelection = ref(false)
const embedError = ref('')
const inputFile = ref<HTMLInputElement | null>(null)
const inputEmbed = ref<HTMLInputElement | null>(null)

const editor = useEditor({
  extensions: [
    // Includes History, ListItem, HardBreak, BulletList, Bold, Italic, Paragraph and Text
    StarterKit,
    Underline,
    Image.configure({
      inline: true,
    }),
    Placeholder.configure({
      placeholder: props.placeholder,
    }),
    TrailingNode.configure({
      node: 'paragraph',
      notAfter: ['paragraph'],
    }),
    // Custom extensions
    Iframe,
    Mention.configure({
      suggestion: {
        items: ({ query }) => {
          if (query === '') return props.wapersSuggestions
          return props.wapersSuggestions.filter((user) =>
            user.name.toLowerCase().includes(query?.toLowerCase()),
          )
        },
        onStart: async ({ items, query, range }) => {
          suggestionRange.value = range
          mentionTooltipRef.value?.show()
          suggestionQuery.value = query
          filteredUsers.value = items
        },
        onChange: ({ items, query, range }) => {
          suggestionRange.value = range
          suggestionQuery.value = query
          filteredUsers.value = items
          navigatedUserIndex.value = 0
        },
        onExit: () => {
          mentionTooltipRef.value?.hide()

          // reset all saved values
          suggestionQuery.value = undefined
          suggestionRange.value = undefined
          filteredUsers.value = []
          navigatedUserIndex.value = 0
        },
        onKeyDown: ({ event }) => {
          if (event.key === 'ArrowUp') {
            upHandler()

            return true
          }

          if (event.key === 'ArrowDown') {
            downHandler()

            return true
          }

          if (event.key === 'Enter') {
            enterHandler()

            return true
          }

          return false
        },
      },
    }),
  ],
  content: modelValue.value,
  onUpdate: () => {
    modelValue.value = editor.value?.getHTML() ?? ''
  },
  onFocus: ({ event }) => {
    if (
      event.relatedTarget &&
      'classList' in event.relatedTarget &&
      event.relatedTarget.classList instanceof DOMTokenList &&
      !event.relatedTarget.classList.contains('c-editor__action')
    ) {
      return emit('enterEditor', true)
    }
  },
  onBlur: ({ event }) => {
    if (
      event.relatedTarget &&
      'classList' in event.relatedTarget &&
      event.relatedTarget.classList instanceof DOMTokenList &&
      !event.relatedTarget.classList.contains('c-editor__action')
    ) {
      return emit('enterEditor', false)
    }
  },
})

defineExpose({
  editor,
})

const hasResults = computed(() => {
  return !!filteredUsers.value.length
})

const imagesCount = computed(() => {
  return modelValue.value.match(/(<img src=)/g)?.length ?? 0
})

const embedCount = computed(() => {
  return modelValue.value.match(/<iframe src=/g)?.length ?? 0
})

const getBeforeCursor = () => {
  const { from, to } = editor.value?.state.selection ?? {}

  if (from !== undefined && from === to && from > 0) {
    const cursorPos = from - 1

    return {
      character: editor.value?.state.doc.textBetween(cursorPos, from),
      range: {
        from: cursorPos,
        to: from,
      },
    }
  }
}

const tryMention = () => {
  const beforeCursor = getBeforeCursor()

  if (beforeCursor?.character === '@') {
    suggestionQuery.value = undefined

    editor.value?.commands.deleteRange(beforeCursor.range)
    return
  }

  editor.value?.commands.insertContent('@')
  editor.value?.commands.focus()
  mentionTooltipRef.value?.show()
}

const addImage = () => {
  if (imagesCount.value >= props.maxImages) {
    return
  }

  inputFile.value?.click()
}

const isMaxEmbedsReached = computed(() => {
  return embedCount.value >= props.maxEmbeds
})

const toggleEmbed = () => {
  if (isMaxEmbedsReached.value) return

  showEmbedSelection.value = !showEmbedSelection.value
}

const addEmbed = () => {
  embedError.value = ''
  const videoInfo = extractVideoId(inputEmbed.value?.value)

  if (!videoInfo) {
    embedError.value =
      'Seules les vidéos de Youtube, Dailymotion et Vimeo sont autorisées.'

    return
  }

  const src = buildEmbedUrl(videoInfo.source, videoInfo.id)

  if (!src) {
    embedError.value = "L'URL de la vidéo est invalide."
    return
  }

  editor.value
    ?.chain()
    .setIframe({
      src,
    })
    .focus()
    .run()

  showEmbedSelection.value = false
}

const upHandler = () => {
  navigatedUserIndex.value =
    (navigatedUserIndex.value + filteredUsers.value.length - 1) %
    filteredUsers.value.length
}

const downHandler = () => {
  navigatedUserIndex.value =
    (navigatedUserIndex.value + 1) % filteredUsers.value.length
}

const enterHandler = () => {
  const user = filteredUsers.value[navigatedUserIndex.value]

  if (user) {
    selectUser(user)
  }
}

const selectUser = async (user: User) => {
  mentionTooltipRef.value?.hide()
  editor.value
    ?.chain()
    .insertMention({ user, range: suggestionRange.value })
    .focus()
    .run()
}

const onFileChange = (event: Event) => {
  const files =
    (event.target instanceof HTMLInputElement && event.target.files) ||
    ('dataTransfer' in event &&
      event.dataTransfer instanceof DataTransfer &&
      event.dataTransfer.files)

  if (!files || !files.length) {
    console.error('no file found')
    return
  }

  const file = files[0]
  const reader = new FileReader()

  reader.addEventListener(
    'load',
    async () => {
      if (typeof reader.result !== 'string') return

      let src = reader.result

      if (
        typeof reader.result === 'string' &&
        reader.result?.includes('image/png;base64')
      ) {
        try {
          const data = await $fetch<{
            optimized?: string
          }>(API.POST_POLISH, {
            method: 'POST',
            body: JSON.stringify({ base: reader.result }),
          })

          if (data?.optimized) {
            src = data.optimized
          }
        } catch (e) {
          console.error(e)
        }
      }

      editor.value?.chain().setImage({ src }).focus().run()
      if (inputFile.value) {
        inputFile.value.value = ''
      }
    },
    false,
  )

  if (file) {
    reader.readAsDataURL(file)
  }
}
</script>

<style lang="scss">
.suggestion-list {
  max-height: 12rem;
  overflow-y: scroll;
  color: #fff;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}

.suggestion-list:not(:hover) {
  .suggestion-list__item {
    &.is-focused {
      color: rgb(0 136 255);
    }
  }
}

.suggestion-list__item {
  &:not(.is-empty) {
    cursor: pointer;

    &:hover {
      color: rgb(0 136 255);
    }
  }
}

.c-editor {
  position: relative;
  border: 1px solid #cbcbcb;

  .mention {
    margin: inherit;
    padding-left: 0;
    color: $blue;
    text-decoration: none;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 16rem;
    max-height: 32rem;
    padding: 2rem;
    overflow-y: auto;

    @media (min-width: 992px) {
      min-height: 22rem;
      max-height: 44rem;
    }
  }

  &__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    font-size: 1.2rem;

    @media (max-width: 700px) {
      font-size: 1.6rem;
    }

    > .ProseMirror {
      flex: 1;
      height: 100%;
    }

    :focus {
      outline: none;
    }

    p {
      margin-bottom: 1.6rem;
      font-size: 1.2rem;
      line-height: 1.4;

      @media (max-width: 700px) {
        font-size: 1.6rem;
      }

      &.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        height: 0;
        color: #686868;
        pointer-events: none;
      }
    }

    img {
      display: block;
      max-width: 100%;
      margin-bottom: 1.6rem;
    }

    ul {
      margin-bottom: 1.6rem;
      padding-left: 1.6rem;
      list-style: disc;
    }

    iframe {
      display: block;
      width: 100%;
      height: 20rem;
      margin: 0 auto 1.6rem;
      border: 0;
    }
  }

  &__menubar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3.6rem;
    border-top: 1px solid #cbcbcb;
  }

  &__action {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0;
    background-color: #fff;
    border: 0;
    border-right: 1px solid #cbcbcb;
    cursor: pointer;
    appearance: none;

    &:last-child {
      border-right: 0;
    }

    &:hover:not(.disabled) {
      background: rgba(0 0 0 / 0.05);
    }

    &.is-active {
      background: rgba(0 0 0 / 0.1);
    }

    &:disabled,
    &.disabled {
      background: rgba(0 0 0 / 0.25);
    }

    img {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &__input {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
  }

  &__embed {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 3.6rem);
    background-color: rgba(0 0 0 / 0.8);
    opacity: 0;
    pointer-events: none;

    &.is-visible {
      opacity: 1;
      pointer-events: all;
    }

    > div {
      width: 100%;
      text-align: center;

      .error {
        width: 90%;
        margin: 0 auto 1rem;
        color: $white;
      }

      > input {
        display: block;
        width: 90%;
        height: 5.5rem;
        margin: 0 auto 1rem;
        padding: 0 2rem;
        color: #333;
        font-size: 1.2rem;
        line-height: 1.5;
        background-color: #fff;
        border: 1px solid #cbcbcb;

        @media (max-width: 700px) {
          font-size: 1.6rem;
        }

        &:focus {
          outline: 0;
        }
      }
    }
  }
}

.tippy-box {
  font-size: 1.2rem;
  text-align: center;
}
</style>
